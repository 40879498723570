<template>
  <l-map
    style="height: 200px"
    :zoom="zoom"
    :center="latLng"
    :min-zoom="minZoom"
    :max-bounds="maxBounds"
    :options="mapOptions"
  >
    <l-tile-layer :url="url"></l-tile-layer>
    <l-marker :lat-lng="latLng">
      <l-icon class-name="custom-leaflet-map-icon-style">
        <v-icon size="56" color="primary">mli-map-pointer</v-icon>
      </l-icon>
    </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
export default {
  components: { LMap, LTileLayer, LMarker, LIcon },
  props: {
    latLng: {
      default: [],
    },
    mapOptions: {
      default: () => ({
        zoomSnap: 0.5,
        scrollWheelZoom: false
      })
    },
  },
  data() {
    return {
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 17,
      center: [25.191999, 55.355398],
      minZoom: 2,
      maxBounds: L.latLngBounds([ 85.03142366737542, -167.95137869021428 ],[ -84.88442800012906, 190.47951089975012 ]),
    };
  },
  mounted() {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  },
};
</script>
<style>
.custom-leaflet-map-icon-style {
  margin-left: -22px !important;
  margin-top: -50px !important;
}
</style>